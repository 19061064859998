import React from 'react'
import { graphql } from 'gatsby'
import TryForFreeForm from 'Components/tryForFree/TryForFreeForm'

interface IProps {
  location: any
  data: {
    stringsJson: any
  }
}

const TryForFreePage: React.FC<IProps> = ({
  data: {
    stringsJson: { tryForFree },
  },
  location,
}) => <TryForFreeForm location={location} message={tryForFree.message} />

export default TryForFreePage

export const query = graphql`
  query TryForFreeQuery($lang: String!) {
    stringsJson(lang: { eq: $lang }) {
      ...TryForFree
    }
    bgImage: file(relativePath: { eq: "contact/contact-intro-bg@2x.png" }) {
      childImageSharp {
        fixed(width: 2560, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
